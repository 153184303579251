import * as React from "react";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout";
import { ContentRenderer } from "../components/ContentRenderer";
import { SEO } from "../components/SEO";
import { Section } from "../components/Section";
import styled from "styled-components";
import { Link } from "../components/Link";
import { BgImage } from "gbimage-bridge";
import { SocialMediaLinksFooter } from "../components";
import qs from "query-string";
import { SubPageGridItem } from "../components/SubPageGridItem";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Pagination } from "../components/Pagination";

const RelatedPosts = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const RelatedPost = styled(Link)`
  background: white;
  h5 {
    margin: 0 10px 10px 10px;
  }
  border: 1px solid ${(p) => p.theme.borderColor};
  transition: box-shadow 0.2s ease;
  cursor: pointer;
  text-decoration: none;
  color: ${(p) => p.theme.bodyFontColor};
  &:hover {
    text-decoration: none;

    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.11);
  }
`;

const Category = styled.div`
  margin: 15px 10px 10px 10px;
  text-transform: uppercase;
  font-size: 12px;
  color: ${(p) => p.theme.mutedColor};
`;

const HomepageTemplate = (props) => {
  const { datoCmsPage } = props.data;
  const { s, p = "1" } = qs.parse(props.location.search);
  const pParsed = parseInt(p);
  const searchTerm = s?.toLowerCase() || "";
  const [filteredPages, setFilteredPages] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState(0);
  const [searching, setSearching] = React.useState(false);
  const pageSize = 20;

  // Logic for main search in website, using DatoCMS API calls to get search results
  React.useEffect(() => {
    if (searchTerm) {
      setSearching(true);
      axios
        .get(
          `https://site-api.datocms.com/search-results?q=${searchTerm}&limit=${pageSize}&offset=${
            (pParsed - 1) * pageSize
          }`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `API-Token 89ebb1d586f0cca032167e22275d1c`,
            },
          }
        )
        .then((r) => {
          setSearching(false);
          setTotalPages(Math.ceil(r.data.meta.total_count / pageSize));
          const deDupedPages = [];

          r.data.data.forEach((page) => {
            let pageUrl = page.attributes.url;
            if (pageUrl[pageUrl.length - 1] !== "/") {
              pageUrl = `${pageUrl}/`;
            }

            const found = deDupedPages.find((deDupedPage) => {
              let deDupedPageUrl = deDupedPage.attributes.url;
              if (deDupedPageUrl[deDupedPageUrl.length - 1] !== "/") {
                deDupedPageUrl = `${deDupedPageUrl}/`;
              }
              return deDupedPageUrl === pageUrl;
            });

            if (!found) {
              deDupedPages.push(page);
            }
          });

          setFilteredPages(deDupedPages);
        });
    } else {
      setFilteredPages([]);
    }
  }, [searchTerm, pParsed]);

  return (
    <>
      <SEO
        title={`${datoCmsPage.seo.title} | Rubric`}
        description={datoCmsPage.seo.description}
        image={datoCmsPage.seo.image?.url}
        twitterCard={datoCmsPage.seo.twitterCard}
        url={props.pageContext.url}
        breadcrumbTrails={[datoCmsPage.breadcrumbs]}
        type="website"
      />
      <Layout s={s}>
        {!!s ? (
          <div style={{ maxWidth: 1100, margin: "20px auto" }}>
            <h1 style={{ textAlign: "center" }}>Search results for: {s} </h1>
            <div style={{ maxWidth: 1100, margin: "20px auto" }}>
              {!!searching && (
                <div
                  style={{
                    marginBottom: 20,
                    color: "#999",
                    textAlign: "center",
                    fontSize: 20,
                  }}
                >
                  Searching... <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
              )}
              <div style={{ maxWidth: 1100, margin: "20px auto" }}>
                {filteredPages.map((page) => {
                  return (
                    <div
                      key={page.id}
                      style={{ marginBottom: 20, position: "relative" }}
                    >
                      {/* When pulling search results from DatoCMS,  "https://rubric.com/en-US" is hardcoded in the URL.
                       We replace this to a blank string and append correct URL later*/}
                      <SubPageGridItem
                        slug={
                          page.attributes.url
                            .toLowerCase()
                            .replace("https://rubric.com/en-us", "") || "/"
                        }
                        description={page.attributes.body_excerpt}
                        title={page.attributes.title}
                      />
                    </div>
                  );
                })}
              </div>
              <div style={{ marginBottom: 20 }}>
                <Pagination
                  totalPages={totalPages}
                  activePage={pParsed}
                  pageButtonDestination={(i) => `?s=${s}&p=${i + 1}`}
                />
              </div>
            </div>
          </div>
        ) : (
          <>
            <ContentRenderer content={datoCmsPage.content} page />
            {!!datoCmsPage.showSocialMediaLinksSection && (
              <SocialMediaLinksFooter />
            )}
            {!!datoCmsPage.pageFooter && (
              <Section
                backgroundImage={
                  datoCmsPage.pageFooter.backgroundImage.gatsbyImageData
                }
                backgroundOverlay={datoCmsPage.pageFooter.backgroundOverlay}
              >
                <ContentRenderer content={datoCmsPage.pageFooter.content} />
                <RelatedPosts>
                  {(datoCmsPage.relatedPosts || []).map((post) => (
                    <RelatedPost key={post.id} to={post.slug}>
                      <BgImage
                        style={{ height: 100 }}
                        image={post.seo.image.gatsbyImageData}
                      />
                      <Category>{post.category.title}</Category>
                      <h5>{post.title}</h5>
                    </RelatedPost>
                  ))}
                </RelatedPosts>
              </Section>
            )}
          </>
        )}
      </Layout>
    </>
  );
};

export const query = graphql`
  query HomepageQuery($id: String) {
    datoCmsPage(id: { eq: $id }) {
      title
      slug
      showSocialMediaLinksSection
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      breadcrumbs {
        id
        name
      }
      pageFooter {
        ...Link_Section
      }
      relatedPosts {
        id: originalId
        slug
        title
        seo {
          title
          description
          image {
            gatsbyImageData
          }
        }
        category {
          title
        }
      }
      content {
        links {
          __typename
          ... on DatoCmsSection {
            ...Link_Section
          }
        }
        blocks {
          __typename
          ... on DatoCmsVerticalRedLine {
            id: originalId
          }
          ... on DatoCmsAlignedText {
            ...Block_AlignedText
          }
          ... on DatoCmsMiniHeading {
            ...Block_MiniHeading
          }
          ... on DatoCmsHighlightedHeading {
            ...Block_HighlightedHeading
          }
          ... on DatoCmsSubPagesList {
            ...Block_SubPagesList
          }
          ... on DatoCmsYoutubeEmbed {
            id: originalId
            youtubeVideo
          }
        }
        value
      }
    }
  }
`;

export default HomepageTemplate;
